import { Link } from '@remix-run/react';

import config from '../../src/config';

export function BookDemoButton() {
  return (
    <Link
      to={config.hubspot.bookDemoUrl}
      className={`w-40 h-10 btn-delete rounded flex items-center justify-center text-sms font-bold font-Montserrat`}
      target='_blank'
    >
      Book a Demo
    </Link>
  );
}
