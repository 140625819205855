import { useNavigate } from '@remix-run/react';
import { useRef, useState } from 'react';
import { match } from 'ts-pattern';

import {
  type DtoPageSection,
  EnumsMediaType,
  EnumsPageName,
  EnumsPageSectionLayoutStyle,
  EnumsPageSectionType,
} from '@lp-lib/api-service-client/public';
import { MediaType } from '@lp-lib/media';

import { type AppAnalytics } from '../../../src/analytics/app/shared';
import { useAwaitFullScreenConfirmCancelModal } from '../../../src/components/ConfirmCancelModalContext';
import { ModalWrapper } from '../../../src/components/ConfirmCancelModalContext/ModalWrapper';
import { CloseIcon } from '../../../src/components/icons/CloseIcon';
import { PlayIcon } from '../../../src/components/icons/PlayIcon';
import { CompaniesUsingLunaParkV2 } from '../../../src/components/Marketing/CompaniesUsingLunaPark';
import { useLiveCallback } from '../../../src/hooks/useLiveCallback';
import { fromMediaDTO } from '../../../src/utils/api-dto';
import { assertExhaustive } from '../../../src/utils/common';
import { MediaPickPriorityHD, MediaUtils } from '../../../src/utils/media';
import { BookDemoButton } from '../BookDemoButton';
import { PageSectionIcon } from './PageSectionIcon';
import { PageDisplayName, pageSectionAnchor } from './utils';

function SectionURLLink(props: PageSectionContainerProps) {
  const { pageName, section, analytics } = props;

  if (!section.url || !section.url.url || !section.url.text) return null;

  return (
    <a
      href={section.url.url}
      target='_blank'
      className='text-sms'
      rel='noreferrer'
      onClick={() =>
        analytics?.trackSectionLinkClicked({
          pageName: PageDisplayName(pageName),
          sectionTitle: section.title,
          linkText: section.url?.text,
          linkUrl: section.url?.url,
        })
      }
    >
      {section.url.text}
    </a>
  );
}

function ExpandedVideo(props: PageSectionContainerProps) {
  const { pageName, section, analytics } = props;

  const [playing, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const media = fromMediaDTO(section.video?.media);
  const url = MediaUtils.PickMediaUrl(media);
  if (!url) return null;

  const handlePlay = () => {
    if (!videoRef.current) return;

    analytics?.trackSectionVideoPlayed({
      sectionTitle: section.title,
      sectionLayout: section.layoutStyle,
      pageName: PageDisplayName(pageName),
    });
    videoRef.current.play();
  };

  return (
    <div className='relative w-full rounded-lg overflow-hidden flex justify-center items-center'>
      <video
        className='w-full rounded-lg'
        src={url}
        poster={media?.firstThumbnailUrl}
        controls={playing}
        ref={videoRef}
        onPlay={() => setPlaying(true)}
        onPause={() => {
          if (videoRef.current?.readyState !== 4) return;
          setPlaying(false);
        }}
      />

      {!playing && (
        <div className='absolute inset-0 bg-lp-black-001 flex flex-col justify-center items-center gap-5'>
          <button
            type='button'
            className='w-14 h-14  rounded-full flex justify-center items-center'
            style={{
              background: 'rgba(111, 111, 111, 1)',
            }}
            onClick={handlePlay}
          >
            <PlayIcon className='w-7 h-7 fill-current text-white' />
          </button>

          <div className='text-sms'>{section.videoText}</div>
        </div>
      )}
    </div>
  );
}

function ExpandedMedia(props: PageSectionContainerProps) {
  const { section } = props;

  const media = fromMediaDTO(section.video?.media);
  if (media?.type === MediaType.Video) {
    return <ExpandedVideo {...props} />;
  }

  const url = MediaUtils.PickMediaUrl(media);
  return (
    <div className='relative w-full flex justify-center items-center'>
      {url && <img src={url} alt='' className='w-full' />}

      {section.videoText && (
        <div className='absolute text-sms'>{section.videoText}</div>
      )}
    </div>
  );
}

function PageSectionHeaderMediaExpanded(props: PageSectionContainerProps) {
  const { section } = props;

  return (
    <div className='w-full pt-15 flex justify-center gap-10'>
      <div className='flex gap-4.5 mt-5'>
        <div className='py-0.75'>
          <PageSectionIcon
            section={section}
            className='w-7.5 h-7.5 fill-current'
          />
        </div>
        <div className='w-115 flex flex-col gap-5'>
          <p className='text-3.5xl leading-9 font-bold font-Montserrat whitespace-pre-line'>
            {section.title}
          </p>
          {section.subtitle && (
            <div className='whitespace-pre-line'>{section.subtitle}</div>
          )}
          {section.showDemoButton && <BookDemoButton />}

          {section.showSocialProof && (
            <div className='flex flex-col items-start gap-2'>
              <p className='text-sms text-icon-gray italic'>
                Trusted by 90,000+ customers in 100+ countries
              </p>
              <CompaniesUsingLunaParkV2 width={320} align='left' />
            </div>
          )}
        </div>
      </div>
      <div className='w-115 2xl:w-150 lp-lg:w-200 flex flex-col items-center gap-5'>
        <ExpandedMedia {...props} />
        <SectionURLLink {...props} />
      </div>
    </div>
  );
}

function PageSectionHeaderMediaCollapse(props: PageSectionContainerProps) {
  const { pageName, section, analytics } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handlePlayVideo = () => {
    analytics?.trackSectionVideoPlayed({
      pageName: PageDisplayName(pageName),
      sectionTitle: section.title,
      sectionLayout: section.layoutStyle,
      videoText: section.videoText,
    });

    const media = section.video?.media;
    const url = MediaUtils.PickMediaUrl(fromMediaDTO(media), {
      priority: MediaPickPriorityHD,
    });
    if (!url) return null;

    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-3/4 max-w-[#1280]'
          innerClassName='rounded-xl'
          borderStyle='gray'
          onClose={p.internalOnConfirm}
        >
          <div className='relative w-full group'>
            <div className='absolute z-10 right-2.5 top-2.5 group-hover:opacity-100 opacity-0'>
              <button
                type='button'
                className='w-7 h-7 flex justify-center items-center rounded-full bg-black bg-opacity-50 hover:bg-opacity-75'
                onClick={p.internalOnConfirm}
              >
                <CloseIcon className='w-4 h-4 fill-current text-white' />
              </button>
            </div>

            <div
              className='w-full rounded-xl overflow-hidden'
              style={{
                aspectRatio: '16/9',
              }}
            >
              {media?.type === EnumsMediaType.MediaTypeVideo ? (
                <video
                  className='w-full h-full object-cover'
                  src={url}
                  controls
                  autoPlay
                />
              ) : (
                <img src={url} alt='' className='w-full h-full object-cover' />
              )}
            </div>
          </div>
        </ModalWrapper>
      ),
    });
  };

  return (
    <div className='relative w-full pt-10 flex justify-center items-center'>
      <div className='absolute top-10 right-12.5'>
        <div className='flex flex-col items-end gap-5'>
          {section.video?.media && section.videoText && (
            <button
              type='button'
              onClick={() => handlePlayVideo()}
              className='btn flex items-center gap-2'
            >
              <div className='w-6 h-6 border border-white rounded-full flex justify-center items-center'>
                <PlayIcon className='w-4 h-4 fill-current' />
              </div>
              <p className='text-sms'>{section.videoText}</p>
            </button>
          )}
          <SectionURLLink {...props} />
        </div>
      </div>

      <div className='flex flex-col items-center'>
        <div className='flex gap-4'>
          <div className='py-0.75'>
            <PageSectionIcon
              section={section}
              className='w-7.5 h-7.5 fill-current'
            />
          </div>
          <p className='text-3.5xl leading-9 font-bold font-Montserrat max-w-144 whitespace-pre-line'>
            {section.title}
          </p>
        </div>
        {section.subtitle && (
          <div className='mt-2.5 max-w-144 text-center whitespace-pre-line'>
            {section.subtitle}
          </div>
        )}
      </div>
    </div>
  );
}

function PageSectionHeaderLiveOTP(props: PageSectionContainerProps) {
  const { section } = props;

  return (
    <div className='w-full pt-20 flex flex-col items-center gap-7 text-center'>
      <div className='max-w-235 text-5xl leading-[54px] tracking-wide font-black font-Montserrat text-[#ff0935] whitespace-pre-line'>
        {section.title}
      </div>
      {section.subtitle && (
        <div className='max-w-180 text-2xl font-Montserrat whitespace-pre-line'>
          {section.subtitle}
        </div>
      )}
    </div>
  );
}

function PageSectionHeader(props: PageSectionContainerProps) {
  if (
    props.pageName === EnumsPageName.PageNameLiveOtp ||
    props.pageName === EnumsPageName.PageNameLiveLoggedIn
  ) {
    return <PageSectionHeaderLiveOTP {...props} />;
  }

  switch (props.section.layoutStyle) {
    case EnumsPageSectionLayoutStyle.PageSectionLayoutStyleMediaExpandedWithButton:
      return <PageSectionHeaderMediaExpanded {...props} />;
    case EnumsPageSectionLayoutStyle.PageSectionLayoutStyleMediaCollapse:
      return <PageSectionHeaderMediaCollapse {...props} />;
    default:
      assertExhaustive(props.section.layoutStyle);
  }
}

export function scrollToPageSection(section: DtoPageSection) {
  const id = pageSectionAnchor(section);
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

export type PageSectionContainerProps = {
  pageName: EnumsPageName;
  section: DtoPageSection;
  sectionIndex: number;

  renderBodyGeneral: () => JSX.Element;
  renderBodyPrograms: () => JSX.Element;

  analytics?: AppAnalytics;
};

export function PageSectionContainer(props: PageSectionContainerProps) {
  const { section } = props;

  return (
    <div className='w-full border-t border-secondary flex flex-col gap-10'>
      <PageSectionHeader {...props} />

      {match(section.type)
        .with(EnumsPageSectionType.PageSectionTypeProgram, () =>
          props.renderBodyPrograms()
        )
        .with(EnumsPageSectionType.PageSectionTypeGeneral, () =>
          props.renderBodyGeneral()
        )
        .exhaustive()}
    </div>
  );
}

export function useNavigateToSection(targetPath: string) {
  const navigate = useNavigate();

  return useLiveCallback((section: DtoPageSection) => {
    if (window.location.pathname === targetPath) {
      scrollToPageSection(section);
      return;
    }

    navigate({
      pathname: targetPath,
      search: window.location.search,
      hash: pageSectionAnchor(section),
    });
  });
}
