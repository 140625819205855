import { type DtoPageSection } from '@lp-lib/api-service-client/public';
import { type MediaFormatVersion } from '@lp-lib/media';

import { fromMediaDTO } from '../../../src/utils/api-dto';
import {
  ImagePickPriorityLowToHigh,
  MediaUtils,
} from '../../../src/utils/media';

export function PageSectionIcon(props: {
  section: DtoPageSection;
  className?: string;
  priority?: readonly MediaFormatVersion[];
}) {
  const { section, className, priority = ImagePickPriorityLowToHigh } = props;
  const url = MediaUtils.PickMediaUrl(fromMediaDTO(section.icon?.media), {
    priority,
  });

  if (!url) return null;
  return <img src={url} alt={''} className={className} />;
}
